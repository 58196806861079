/* eslint-disable no-extend-native */
/* eslint-disable func-names */
import resizeObserverPolyfill from "resize-observer-polyfill";

/**
 * Polyfills
 * Using JavaScript instead of TypeScript as TypeScript was having issues with
 * some usages of 'this'.
 */

/**
 * Object.values
 */
Object.values = Object.values
  ? Object.values
  : (o) => Object.keys(o).map((k) => o[k]);

/**
 * Array.prototype.flat
 */
Array.prototype.flat = Array.prototype.flat
  ? Array.prototype.flat
  : function (...args) {
      const depth = typeof args[0] === "undefined" ? 1 : Number(args[0]) || 0;
      const result = [];
      const { forEach } = result;

      const flatDeep = function (arr, depthInner) {
        forEach.call(arr, function (val) {
          if (depthInner > 0 && Array.isArray(val)) {
            flatDeep(val, depthInner - 1);
          } else {
            result.push(val);
          }
        });
      };

      flatDeep(this, depth);
      return result;
    };

/**
 * Array.prototype.flatMap
 */
Array.prototype.flatMap = Array.prototype.flatMap
  ? Array.prototype.flatMap
  : function (...args) {
      return Array.prototype.map.apply(this, args).flat(1);
    };

/**
 * ResizeObserver
 */
window.ResizeObserver = window.ResizeObserver
  ? window.ResizeObserver
  : resizeObserverPolyfill;

// An export is needed for this file to be considered a module.
export {};
